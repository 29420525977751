/* input */
.pass-eye {
  right: 10px;
  bottom: 13px;
}
.form-floating .pass-eye {
  bottom: 17px;
}
.input-wrap .form-control,
.form-select {
  border-color: #ccc !important;
  border: 1.5px solid;
  color: #2f4050;
  padding: 10px !important;
  border-radius: 0;
}
input:focus,
.form-select:focus {
  box-shadow: none !important;
  border-color: #2f4050 !important;
}
.form-check-input:checked {
  background-color: #2f4050;
  border-color: #2f4050;
}
/* buttons */
.btn {
  padding: 8px 20px;
  font-weight: 500;
}
.btn-dark {
  background-color: #044d37;
  color: white;
}
.btn-dark:hover {
  background-color: #646464;
  border-color: #646464;
  color: #e2e8f0;
}

/* links */
a {
  text-decoration: none;
  color: #293846;
}
/* alert */
.alert {
  position: absolute;
  font-weight: bold;
  top: -6%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.2s ease-out;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.alert-visible {
  top: 6%;
  transition: all 0.2s ease-in;
}
.alert-success {
  background-color: azure !important;
  color: #2f4050;
}
.alert-danger {
  background-color: #fff;
  color: rgb(134, 8, 8);
  border-color: rgb(134, 8, 8);
}
/* table */
.table-dropdown select {
  padding: 5px 10px !important;
}
.no-data-img {
  max-height: 150px;
}

/* modal */
.modal-custom {
  min-width: 300px;
  max-width: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease;
  z-index: 2;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

/* ---------------pagination--------------- */
.pagination .page-item {
  margin: 0 5px;
}
.page-item:first-child .page-link {
  border: none;
  background-color: transparent;
}
.page-item:last-child .page-link {
  border: none;
  background-color: transparent;
}
.pagination .page-link {
  color: #293846 !important;
  border: none;
  background-color: transparent;
}
.pagination .page-item.active .page-link {
  color: #000 !important;
  background-color: #d5d8dd !important;
  border-radius: 8px;
}
.pagination .page-link:focus {
  box-shadow: none;
}

/* switch */
.switch-wrapper {
  position: relative;
  width: 120px;
  z-index: 1;
}
.switch-wrapper input {
  padding: 13px !important;
  height: 20px;
  width: 120px !important;
  outline: none;

  background-color: rgb(255, 41, 41);
}
.switch-wrapper input:checked {
  background-color: rgb(128, 139, 128);
  background-position-x: 95px !important;
}
.form-switch .form-check-input {
  background-image: url(https://aceplumbers.co.uk/wp-content/uploads/2020/08/create-meme-white-square-white-square-white-background-png-white-background-png-500_492.jpg) !important;
  background-size: 19px 20px;
  background-position-x: 3px;
  border-radius: 4px;
}
.switch-label {
  position: absolute;
  color: white;
  top: 5px;
  z-index: 0;
  font-weight: 500;
}
.activa {
  left: 5px;
}
.deactiva {
  right: 5px;
}
.review-popup .modal-custom {
  max-width: 500px;
  min-width: 350px;
}

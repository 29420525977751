/* authentication */

.register-form .drop-wrap label {
  display: none;
}
.register-form .drop-wrap select {
  padding: 1rem 0.75rem;
}
.otp-input {
  max-width: 45px;
  height: 40px;
}
.otp-input:read-only {
  background-color: white;
}

/* profile */
.profile-img-wrapper img {
  width: 105px;
}

/* pages */
.bread-crumb-wrapper {
  padding: 1rem;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.req-doc-close {
  right: -10px;
  top: -10px;
}

.has-variants label {
  display: none;
}
.add-images-input div,
.add-images-input label {
  height: 100%;
}
.add-images-input label {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.multi-img-close-btn {
  position: absolute;
  top: -13px;
  right: -13px;
}
.add-images-input label svg {
  font-size: 30px;
}
.add-images-wrapper {
  position: relative;
  max-width: 150px;
  margin-right: 14px;
}

/* orders */
.order-img {
  max-height: 5rem;
}
.order-detail-status .drop-wrap label {
  display: none;
}
/* message */
.profile-img-sm {
  height: 3rem;
  width: 3rem;
}
.profile-img-sm img {
  max-width: 100%;
  max-height: 100%;
}
.message-paragraph {
  width: max-content;
}
.message-sent-input:focus {
  box-shadow: none;
  border: none;
  outline: 0;
}
.message-doc-icon {
  right: 2.5rem;
  top: 1rem;
}
.review-img {
  max-height: 6rem;
}
.review-img-close {
  top: -5px;
  right: -5px;
  position: absolute;
}

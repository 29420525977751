* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Roboto", sans-serif;
  color: #293846;
  background-color: #ffffff;
}
/* colors */
.text-default {
  color: #293846;
}
.primary-bg {
  background-color: #2f4050;
}
.tertiary-bg {
  background-color: #044d37;
}
.text-primary {
  color: #2f4050;
}
.secondary-bg {
  background-color: #293846;
}
.text-secondary {
  color: #293846;
}
.fade-bg {
  background-color: #e2e8f0;
}
.text-fade {
  color: #e2e8f0;
}
.fw-500 {
  font-weight: 500;
}
.fw-700 {
  font-weight: 700;
}

/* utilities */
.c-pointer {
  cursor: pointer;
}
.rounded-8 {
  border-radius: 8px;
}
.rounded-12 {
  border-radius: 12px;
}
.text-sm {
  font-size: 14px;
}
